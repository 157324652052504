@import "src/styles/_colors";

.slider {
  padding: 0;

  &__item {
    display: grid;
    gap: 32px;
    align-items: center;
    padding-right: 7%;
    grid-template-columns: 142px auto;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 70px auto;
      gap: 15px;
    }

  }

  &__review {
    color: $white;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: normal;
    margin: 0;

    @media screen and (max-width: 1180px) {
      font-size: 20px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }

  }

  &__post {
    color: $white;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.71;
    margin-top: 12px;
  }

  &__photo {
    position: relative;
    width: 142px;
    height: 142px;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 12px 24px 0 rgba(36, 67, 82, 0.07);
    border: 3px solid $white;

    @media screen and (max-width: 1024px) {
      width: 70px;
      height: 70px;
    }
    &__tmp {
      display: none;
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      //display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }

    img {
      //opacity: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

}