@import "src/styles/_colors";

.wrap {
  font-family: 'Open Sans', sans-serif;
  background-blend-mode: overlay, overlay, normal;
  background-image: radial-gradient(circle at 18% 25%, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 53%), radial-gradient(circle at 78% 41%, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0) 80%), linear-gradient(115deg, #15a5c4 0%, #0d8ba6 100%);
  top: -71px;
  position: relative;
  border-radius: 0 0 36px 36px;
  @include mobile() {

  }
  @include tablet() {
  }
  @include desktop() {
    border-radius: 36px;
  }
}
.main {
  display: flex;
  flex-direction: column-reverse;
  //padding-top: 82px;
  padding-top: 133px;
  padding-bottom: 156px;
  justify-content: space-between;
  @include tablet() {
    flex-direction: row;
  }
}
.heroText {
  flex-grow: 1;
  @include tablet() {
    flex: 1 1 327px;
    max-width: 327px;
    padding-right: 20px;
  }

  @include desktop() {
    padding-top: 52px;
    flex: 1 0 465px;
    padding-right: 40px;
    max-width: 466px;
  }
}
.heroTextSmall {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.85;
  color: #fff;
  text-transform: uppercase;
  @include desktop() {
    margin: 0 0 6px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
  }
}
.heroTitle {
  margin: 0 0 28px 0;
  font-size: 42px;
  font-weight: 800;

  line-height: 1.14;
  color: #ffffff;

  @include desktop() {
    font-size: 60px;
    line-height: 1.2;
    color: #ffffff;
    margin: 0 0 28px 0;
  }
}
.heroSubTitle {
  max-width: 320px;
  margin: 24px 0 40px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;


  @include desktop() {
    max-width: 100%;
    font-size: 24px;
    margin: 28px 0 58px;
    font-weight: bold;
    line-height: 1.67;
  }
}
.heroImgWrap {
/*  display: flex;
  justify-content: center;
  flex-grow: 1;*/
  padding-bottom: 40px;
  position: relative;
  @include desktop() {
    padding-bottom: 0;
    display: block;
    max-width: 575px;
    flex: 1 1 575px;
  }

}

.pictureWrap {
  display: block;
  //width: 100%;
  flex-grow: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  @include tablet() {
    position: static;
  }
}
.heroImg {
  max-width: 100%;
  width: 100%;
  flex-grow: 1;
  @include desktop() {
    max-width: 103%;
    transform: translate(1%, 0px);
  }
}
// 325X 223
.placeHolder {
  padding-bottom: 68%;
  @include tablet() {
    display: none;
  }
}
