@import "src/styles/_colors";

.container {
  max-width: 100%;
  padding: 0 25px;
  margin: 0 auto;
  flex: 1 0 100%;
  box-sizing: border-box;
  @include mobile() {

  }
  @include tablet() {
  }
  @include desktop() {
    max-width: 1200px;

  }
}