@import "../../styles/_colors";

.wrap {
  padding-top: 100px;
  @include desktop() {
    padding-top: 120px;
    margin-top: 80px;
  }
}
.item {
  display: flex;
  flex-direction: column;
  & + & {
    margin-top: 100px;
  }
  @include desktop() {
    flex-direction: row;
    & + & {
      margin-top: 163px;
    }
  }
}
.itemRightSideRightAligned {
  flex-direction: column-reverse;
  @include desktop() {
    flex-direction: row;
  }
}
.oneLine {
  white-space: nowrap;
}
.leftSide {

  flex: 1 0;
  @include desktop() {
    width: calc(100% / 2 + 30px);
  }
}
.leftSideAddPaddingOnMobile {
  padding-right: 47px;
  @include desktop() {
    padding-right: 0;
  }
}
.rightSide {
  flex: 1 0;
  @include desktop() {
    width: calc(100% / 2 - 30px);
  }
}
.rightSideRightAligned {
  text-align: right;
  padding-left: 47px;
  @include desktop() {
    padding-left: 0;
  }

}
.itemTitle {
  margin: 48px 0 32px;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.5;

  color: #000000;
  @include desktop() {
    margin: 0 0 24px;
    font-size: 48px;
  }
}
.itemDescription {
  margin: 0 0 32px;
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
}
.list {
  margin: 0 0 48px;
  list-style: none;
  padding: 0;
}
.listItem {
  position: relative;
  padding-left: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  color: #333333;
  & + & {
    margin-top: 16px
  }
  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    width: 8px;
    height: 8px;
    left: 0;
    top: 50%;
    margin-top: -4px;
    background-blend-mode: overlay, normal;
    background-image: radial-gradient(circle at 4% 3%, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 74%), linear-gradient(135deg, #12a0bf 0%, #0283a0);
  }
}

.itemImg {
  max-width: 100%;
  width: 100%;
}
