@import "src/styles/_colors";


.wrap {

  box-sizing: border-box;
  padding: 60px 24px 155px;


  @include tablet() {

    width: 690px;
    min-height: 680px;
    padding: 60px 100px 55px;
  }
}
.formTitle {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.25;
  color: #000;
  text-align: center;
  margin: 12px 0 36px;
  @include tablet() {
    height: 72px;
    margin: 0 0 30px;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.5;
    color: #000000;
  }
}
.btnsWrap {
  display: flex;

  flex-direction: column;
  @include tablet() {
    margin: 30px -10px;
    flex-direction: row;
  }
}
.authBtn {

  box-sizing: border-box;

  text-decoration: none;
  height: 48px;
  margin: 0 0 16px 0;
  padding: 0 24px;
  border-radius: 24px;
  border: solid 1px #a3a3a3;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  transition: transform .45s cubic-bezier(.25,1,.33,1),box-shadow .45s cubic-bezier(.25,1,.33,1),-webkit-transform .45s cubic-bezier(.25,1,.33,1);
  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.16);
    transform: translateY(-3px);
  }
  @include tablet() {
    margin: 0 10px;
    flex: 1 1;
    width: calc(100% / 3 - 20px);
  }
}
.btnIcon {
  height: 18px;

  margin-right: 10px;
  //margin-top: 2px;
  display: inline-block;
  vertical-align: middle;
}
.separator {
  text-transform: uppercase;
  position: relative;
  height: 27px;
  margin: 0 15px 20px;
  opacity: 0.3;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  color: #000000;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    display: block;
    height: 1px;
    background-color: #d5d5d5;
    flex: 1 0;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d5d5d5;
    flex: 1 0;
  }
  span {
    padding: 0 15px;
  }
}
.formFooter {
  text-align: center;
}
.footerActions {
  margin-top: 28px;
  font-size: 16px;
  color: #000000;
}
.footerActionsBottom {
  margin-top: 15px;
}
.link {
  width: 57px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333333;
  transition: all .3s ease-in;
  &:hover {
    color: #ffa857;
  }
}

.textMessage {
  margin: 30px 0 30px;
  font-size: 16px;
  color: #000000;
  line-height: 1.4;
  text-align: center;
}
.formWrapContent {
  position: relative;
}
.textMessageSuccess {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  top: 0;
  font-size: 16px;
  line-height: 1.4;
  color: #000000;
  text-align: center;
}
.formFooterMargin {
  margin-top: 164px;
}