@import "../../styles/_colors.scss";


.wrap {
  //padding: 200px 0 0;
  padding-top: 100px;
  @include tablet() {
    padding-top: 120px;
    margin-top: 80px;
  }
}
.colsWrap {
  display: flex;
  flex-direction: column;
  margin: 0 -25px;
  @include tablet() {
    margin: 0 -15px;
    flex-direction: row;
  }
}
.col {
  margin: 10px 0;
  width: calc(100%);
/*  @include tablet() {
    margin: 10px 15px 0;
    width: calc(100% / 2 - 15px);
  }*/
  @include tablet() {
    margin: 10px 15px 0;
    width: calc(100% / 3 - 15px);
  }
}
.col2  {
  padding-bottom: 28px;
  overflow: hidden;
  margin: -50px 0 0;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
  min-height: 265px;

  @include tablet() {
    padding: 0;
    flex-wrap: wrap;
    margin: 10px 15px -20px;
    width: calc(100% / 3 * 2 - 15px);
  }
}
.sliderWrap {
  overflow-x: visible;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
  min-height: 265px;
  transition-property: transform;
  //transition-timing-function: linear;
  will-change: transform;
  @include tablet() {
    padding: 0;
    flex-wrap: wrap;
  }
}
.hero {
  position: relative;
  padding: 60px 24px 0;
  border-radius: 36px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-blend-mode: overlay, overlay, normal;
  background-image: radial-gradient(circle at 4% 3%, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 94%), radial-gradient(circle at 92% 70%, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0) 64%), linear-gradient(156deg, #12a0bf 0%, #0283a0 100%);
  @include tablet() {
    padding: 60px 0 0;
    border-radius: 20px;

  }
}
.heroTitle {
  height: 96px;
  width: 160px;
  font-size: 42px;
  font-weight: 800;
  line-height: 1.14;
  color: #fff;
  margin: 0 0 24px 0;

  @include tablet() {
    width: auto;
    height: auto;
    padding: 0 40px;
    font-size: 60px;
    font-weight: 800;
    line-height: 1.2;
    color: #ffffff;
    margin: 0 0 30px 0;
  }
}
.heroText {
  font-weight: bold;
  line-height: 1.5;
  color: #ffffff;
  font-size: 18px;

  @include tablet() {
    padding: 0 54px 11px 40px;
    font-size: 24px;
  }
}
.heroImgWrap {
  position: relative;
  padding-bottom: 109%;
  @include tablet() {
    padding: 0 2px 109%;
    transform: translate(18px, 0px);
  }
}
.heroImg {
  position: absolute;
  //z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  display: block;
}
.bullet {
  touch-action: pan-x;
  width: calc(100% - 48px);
  min-width: calc(100% - 48px);
  box-sizing: border-box;
  padding: 30px 40px 40px;
  border-radius: 20px;
  box-shadow: 0 12px 24px 0 rgba(36, 67, 82, 0.07);
  background-color: #ffffff;
  margin: 0;
  min-height: 265px;
  & + & {
    margin-left: 12px;
  }
  &:first-child {
    margin-left: 24px;
  }
  @include tablet() {
    min-width: auto;
    margin: 0 15px 20px;
    width: calc(100% / 2 - 30px);
    &:first-child {
      margin: 0 15px 20px;
    }
    & + & {
      margin: 0 15px 20px;
    }
  }
  opacity: 1;
  transition-property: transform;
  transition-timing-function: linear;
  will-change: transform;
}
.bulletRow {
  display: flex;

  & + & {
    margin-top: 20px;
  }
  @include tablet() {
    margin: 0 -15px;
  }
}
.bulletImg {
  width: 54px;
  height: 54px;
  margin: 0;
  display: block;
  object-fit: contain;
}
.bulletTitle {
  margin: 16px 0 8px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #000000;
}
.bulletText {
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
}

.sliderDots {
  padding-top: 16px;
  display: flex;
  justify-content: center;
  @include tablet() {
   display: none;
  }
}

.sliderDotsItem {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #12a3bc;
}
.sliderDotsItemActive {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  background-color: #fc9a51;
}