.video {
  max-width: 1200px;
  padding: 0 ;
  margin: 0 auto 120px;

  &__title {
    font-size: 48px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
    text-align: center;
    margin: 0 0 35px;

    @media screen and (max-width: 768px) {
      font-size: 36px;
      margin: 0 0 20px;
    }

  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #000000;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.12);
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    padding: 0;
    border: none;
    background-color: transparent;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 96px;
    height: 96px;
    object-fit: contain;

    svg {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 768px) {
      width: 48px;
      height: 48px;
    }
  }

}