@import "src/styles/_colors";

.businessesBanner {
  border-radius: 36px;
  background-blend-mode: overlay, normal;
  background-image: radial-gradient(circle at 72% 52%, rgba(255, 255, 255, 0.36), rgba(0, 0, 0, 0) 125%), linear-gradient(111deg, #12a0bf 0%, #0283a0);
  padding: 50px 0;
  margin-top: 300px;

  @media screen and (max-width: 1024px) {
    margin-top: 200px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }

  &__title {
    font-size: 60px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: $white;
    margin: 0 0 24px;

    b {
      font-weight: 800;
    }

    @media screen and (max-width: 1024px) {
      font-size: 48px;
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      font-size: 30px;
    }

  }

  &__text {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $white;
    margin-bottom: 48px;

    @media screen and (max-width: 1024px) {
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }

  }

  &__textContainer {
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 390px auto;
    column-gap: 100px;
    align-items: center;

    @media screen and (max-width: 1180px) {
      column-gap: 40px;
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }

  }

}