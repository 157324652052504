$primary: #41AAE9;
$primaryLight: #a0d4f4;
$primaryAccent: #0086d6;
$dark: #454545;
$light: #cccccc;
$black: rgba(0, 0, 0, 0.87);
$darker: #666;
$navGStart: #55A2F6;
$navGEnd: #4EB9F9;
$white: #ffffff;
$gray: #f2f5f7;
$moreGray: #c0c0c0;
$green: #2fc77a;
$greenlight: #2bb36f;
$darkGreen: #00755c;
$borderGray: #e6edf2;
$lightGreen: #15cc95;
$lightGreen2: #5ddab4;
$lightGray: #999999;
$grayBlue: #567894;
$grayDark: #333333;
$orange: #fba550;
$red: #fd5c52;
$redError: #f44336;
$purple: #8f3cf4;
$lightGrayBlue: #cfdae3;
$lightGrayWithOpacity: rgba(13, 207, 218, 0.15);
$grayShadow: rgba(72, 107, 125, 0.15);
$blueShadow: rgba(72, 119, 168, 0.5);
$blueShadowLight: rgba(72, 119, 168, 0.2);
$darkBlueShadow: rgba(57, 98, 170, 0.3);
$orangeShadow: rgba(251, 165, 80, 0.15);
$greenShadow: rgba(21, 204, 149, 0.15);
$redShadow: rgba(253, 92, 82, 0.15);
$grayShadowLight: rgba(69, 69, 69, 0.5);

$skeletonGrayStart:  #ececec;
$skeletonGrayEnd:  #f5f5f5;

//$primaryRGB: 72, 119, 168;
//$purpleRGB: 143, 60, 244;

@mixin z-depth-transition() {
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
}

@mixin z-depth-focus($r: 0, $g: 0, $b: 0) {
  box-shadow: 0 0 8px rgba($r, $g, $b, .18), 0 8px 16px rgba($r, $g, $b, .36);
}

@mixin z-depth-2dp($r: 0, $g: 0, $b: 0) {
  box-shadow: 0 2px 2px 0 rgba($r, $g, $b, .14),
  0 1px 5px 0 rgba($r, $g, $b, .12),
  0 3px 1px -2px rgba($r, $g, $b, .2);
}

@mixin z-depth-3dp($r: 0, $g: 0, $b: 0) {
  box-shadow: 0 3px 4px 0 rgba($r, $g, $b, .14),
  0 1px 8px 0 rgba($r, $g, $b, .12),
  0 3px 3px -2px rgba($r, $g, $b, .4);
}

@mixin z-depth-4dp($r: 0, $g: 0, $b: 0) {
  box-shadow: 0 4px 5px 0 rgba($r, $g, $b, .14),
  0 1px 10px 0 rgba($r, $g, $b, .12),
  0 2px 4px -1px rgba($r, $g, $b, .4);
}

@mixin z-depth-6dp($r: 0, $g: 0, $b: 0) {
  box-shadow: 0 6px 10px 0 rgba($r, $g, $b, .14),
  0 1px 18px 0 rgba($r, $g, $b, .12),
  0 3px 5px -1px rgba($r, $g, $b, .4);
}

@mixin z-depth-8dp($r: 0, $g: 0, $b: 0) {
  box-shadow: 0 8px 10px 1px rgba($r, $g, $b, .14),
  0 3px 14px 2px rgba($r, $g, $b, .12),
  0 5px 5px -3px rgba($r, $g, $b, .4);
}

@mixin z-depth-16dp($r: 0, $g: 0, $b: 0) {
  box-shadow: 0 16px 24px 2px rgba($r, $g, $b, .14),
  0  6px 30px 5px rgba($r, $g, $b, .12),
  0  8px 10px -5px rgba($r, $g, $b, .4);
}

@mixin z-depth-24dp($r: 0, $g: 0, $b: 0) {
  box-shadow: 0  9px 46px  8px rgba($r, $g, $b, .14),
  0 24px 38px  3px rgba($r, $g, $b, .12),
  0 11px 15px -7px rgba($r, $g, $b, .4);
}


$mobile-width: 375px;
$tablet-width: 768px;
$desktop-width: 1024px;
$wide-breakpoint: 1250px;
$extra-wide-breakpoint: 1650px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin wideDesktop {
  @media (min-width: #{$wide-breakpoint}) {
    @content;
  }
}

@mixin extraWideDesktop {
  @media (min-width: #{$extra-wide-breakpoint}) {
    @content;
  }
}

@mixin preloader {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: $light;
    background-image: linear-gradient(90deg, transparent, $purple, transparent);
    background-size: 300px 100%;
    background-repeat: no-repeat;
    animation: linearProgress 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    @content;
  }
}

@keyframes linearProgress {
  from {
    background-position: -300px 0;
  }
  to {
    background-position: calc(100% + 300px) 0;
  }
}