@import "../styles/_colors";

.wrap {
  background-color: #f1fbfe;

  @include mobile() {

  }
  @include tablet() {
  }
  @include desktop() {
    padding: 20px;
  }
}