@import "src/styles/_colors";

.menuWrap {
  margin: 0;
  padding: 0;
  position: sticky;
  z-index: 4;
  top: -20px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile() {

  }
  @include tablet() {

  }
  @include desktop() {
    margin: 0 -20px;
    height: 72px;
    padding: 0 20px;
  }
}
.menuWrapSticky {

}

.nav {
  // padding-top: 29px;
  display: none;
  width: 100%;
  justify-content: space-between;
  transform: translate(0, 20px);
  position: relative;
  @include mobile() {

  }
  @include tablet() {


  }
  @include wideDesktop() {
    display: flex;
    border-radius: 36px;
  }
}
.navSticky {
  transform: translate(0, 0px);
}
.menuBg {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 20px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  //overflow-x: clip;
  //transition: opacity .1s ease;
  box-shadow: 0px -10px 24px 10px rgba(3, 67, 80, 0.65);
  background-image: linear-gradient(92deg, #15a5c4 0%, #0d8ba6 100%);
 /* &:before {
    content: '';
    position: absolute;
    left: -20px;
    top: 0;
    width: calc(100% + 40px);
    height: 100%;
    box-shadow: 0 2px 24px 0 rgba(3, 67, 80, 0.65);
  }*/
}

/*.menuBgSticky {
  opacity: 1;
}*/

.navMenu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  @include mobile() {

  }
  @include tablet() {


  }
  @include desktop() {
   // border-radius: 36px;
  }
}
.menuItem {
  display: flex;
  align-items: center;
/*  & + & {
    margin-left: 45px
  }*/
}
.menuItemLink {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 40px;
  position: relative;
}
.menuItemLink:after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0;
  transition: width .3s cubic-bezier(0.250, 0.460, 0.450, 0.940), opacity .3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}
.menuItemLink:hover:after {
  opacity: 1;
  width: 100%;
}
.menuItemLinkActive:after {
  opacity: 1;
  width: 100%;
}
.rightMenu {
  display: flex;
  align-items: center;
}
.leftSide {
  display: flex;
}

.mobileMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transform: translate(0, 20px);
  @include wideDesktop() {
    display: none;
  }

}
.mobileLogo {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  opacity: 0;
}
.mobileMenuLeft {
  display: flex;
  align-items: center;
}
.mobileNavLink {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
}
.menuToggler {
  width: 24px;
  height: 20px;
  cursor: pointer;
  margin-left: 38px;

  &:before {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 5px;
  }
  &:after {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 8px 0 #fff;

  }
}
.mobileNavWrap {
  padding: 24px;
}
.navMobileMenu {
  list-style: none;
  margin: 80px 0 40px;
  padding: 0;

}
.menuItemMobile {
}
.menuItemMobileLink {
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 68px;
  text-align: center;
  color: #000;
}
.menuItemMobileLinkActive span{
  position: relative;
}
.menuItemMobileLinkActive span:after{
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #000;
}