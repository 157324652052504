@import "src/styles/_colors";

.wrap {
  padding-top: 100px;
  @include desktop() {
    padding-top: 120px;
    margin-top: 80px;
  }
}
.formWrap {
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;

  @include desktop() {
    width: 600px;
  }
  @include wideDesktop() {
    background-color: #ffffff;
    width: 791px;
    padding: 80px 103px 100px;
    box-shadow: 0 8px 24px 0 rgba(36, 67, 82, 0.07);
    border: solid 1px #f7f7f7;
  }
}
.formTitle {
  font-weight: 800;
  font-size: 42px;
  line-height: 1.14;
  color: #000;
  text-align: center;
  @include tablet() {
    height: 72px;
    margin: 0 0 60px;
    font-size: 60px;
    line-height: 1.2;
    text-align: center;
    color: #151320;
  }

}
.formFooter {
  padding-top: 60px;
  text-align: center;
}
.formField {
  display: flex;
  flex-direction: column;
  @include tablet() {
    flex-direction: row;
  }
}
.formFieldItem {
  flex: 1 1 100%;

  @include tablet() {
    & + & {
      margin-left: 20px;
    }
  }
}