@import "src/styles/_colors";

.hideOverflow {
  //overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

}
.overlay {
  background-color: rgba(241, 251, 254, 1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: fade;
  @include tablet() {
    background-color: rgba(241, 251, 254, 0.9);
  }
}
.modalContent {
  position: relative;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: anim-open;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 20px;
  box-shadow: 0 12px 24px 0 rgba(36, 67, 82, 0.07);
  background-color: #ffffff;
  @include tablet() {
    width: auto;
    height: auto;
  }
}
.closeBtn {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  z-index: 1;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: fade;
  transition: transform .3s ease-in;
  &:hover {
    transform: rotateZ(90deg);
  }
  @include desktop() {
    top: 60px;
    right: 80px;
  }

}
@keyframes anim-open {
  0% { opacity: 0; transform: scale3d(1.1, 1.1, 1); }
  100% { opacity: 1; transform: scale3d(1, 1, 1); }
}
@keyframes fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes anim-close {
  0% { opacity: 1; }
  100% { opacity: 0; transform: scale3d(0.9, 0.9, 1); }
}