.input {
  display: block;
  background-color: #ffffff;
  padding: 19px 24px;
  height: 65px;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  border-radius: 20px;
  border: 1px solid #a3a3a3;
  margin-bottom: 24px;
  max-width: 100%;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid #ffa857;
    outline: none;
  }
  &::placeholder {
    color: rgba(0,0,0, 0.3)
  }
}
.error {
  border: 1px solid #f44336;
  color: #f44336;
  &:focus {
    border: 1px solid #f44336;
    outline: none;
  }
}
.errorsWrap {
  margin-top: -20px;
  margin-bottom: 20px;
}
.errorText {
  font-weight: bold;

  color: #f44336;
  & + & {
    margin-top: 5px;
  }
}
.textarea {
  height: auto;
}