@import "src/styles/_colors";

.TitanAlert {
  color: #fff;
  background-color: $red;
  padding: 10px 15px;
  margin-bottom: 20px;
  &--warning {
    background-color: $orange;
  }
}
