@import "src/styles/_colors";

.wrap {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  @include desktop() {
    flex-direction: row;
    padding-top: 120px;
    //margin-top: 80px;
    padding-bottom: 0;
  }
}
.colLarge {
  @include tablet() {
    width: calc(100% / 4 + 100px);
    margin: 20px;
  }
}
.col {
  @include tablet() {
    width: calc(100% / 4);
    margin: 20px;
  }
}

.footerTitle {
  margin-bottom: 26px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #000000;
  text-transform: uppercase;
}
.footerDescription {
    opacity: 0.5;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
}
.labelLink {
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
  margin-bottom: 24px;
  display: block;
  text-decoration: none;
  &:hover {
    color: #F88547
  }
}
.iconLink {
  display: inline-block;
  & + & {
    margin-left: 33px;
  }
}