@import "src/styles/_colors";

.wrap {
  background: #fff;
  border-radius: 20px;
  padding: 60px 40px 40px;
  box-shadow: 0 12px 24px 0 rgba(36, 67, 82, 0.07)
}

.logoWrap {
  text-align: center;

  img {
    width: 200px;
  }
}

.header {
  margin: 0 0 8px;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.5;
  color: #333;
  text-align: center;
}

.promoHeader {
  color: #f57941;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
  margin: 0 0 46px;
}

.content {
  display: grid;
  max-width: 970px;
  margin: 0 auto;
  gap: 24px;
  margin-bottom: 77px;
  grid-template-columns: 1fr;

  @include tablet() {
    grid-template-columns: repeat(2, 1fr);
  }
}

.contentItem {
  width: 100%;
  box-sizing: border-box;
  padding-left: 63px;
  position: relative;

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.contentItemTitle {
  font-weight: bold;
  margin-bottom: 10px;
}

.contentItemText {
  max-width: 380px;
}

.footer {
  display: flex;
  justify-content: center;
}

.footerPrice {
  color: #000;
  opacity: .5;
  font-size: 16px;
  text-align: center;
  line-height: 1.69;
  margin-top: 16px;
}