@import "src/styles/_colors";

.btn {
  width: 100%;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  border-radius: 32.5px;
  height: 48px;
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  line-height: 48px;
  text-align: center;
  color: #fff;
  padding: 0 30px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #ffa857 8%, #f57941 90%);
  transition: transform .45s cubic-bezier(.25,1,.33,1),box-shadow .45s cubic-bezier(.25,1,.33,1),-webkit-transform .45s cubic-bezier(.25,1,.33,1);
  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #fba351 8%, #ed6d34 90%);
    transform: translateY(-3px);
  }
  @include tablet() {
    width: auto;
  }
}

.btnLarge {
  font-size: 16px;
  text-align: center;
  height: 64px;
  line-height: 64px;
  min-width: 270px;
  padding: 0 55px;
  box-sizing: border-box;
  @include tablet() {
    font-size: 18px;
  }
}
.secondary {
  background-image: linear-gradient(2deg, #54b1e6 0%, #83d4e8 171%);
  &:hover {
    background-image: linear-gradient(to bottom, #83d4e8 8%, #54b1e6 90%);
  }
}
.btn[disabled] {
  background: #d5d5d5;
  box-shadow: none;
  &:hover {
    transform: none;
    box-shadow: none;
    background: #d5d5d5;
  }
}
.noShadow {
  box-shadow: none;
}
