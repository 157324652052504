.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
  }
}


.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 250px;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slide {
  opacity: .5;
  transition: opacity ease-in-out .2s;

  &.slick-current {
    & + .slick-slide {
      opacity: 1;
    }
  }
}

.slick-dots {
  position: absolute;
  right: 2%;
  top: 0;
  bottom: 0;
  justify-content: center;
  display: flex !important;
  flex-direction: column;
  list-style: none;
  padding: 0;
  gap: 12px;

  > li {

    width: 12px;
    height: 12px;
    opacity: 0.5;
    background-color: #fff;
    border-radius: 100%;
    transition: opacity ease-in-out .2s;
    display: block;

    button {
      opacity: 0;
    }

    &.slick-active {
      opacity: 1;
    }

  }
}

