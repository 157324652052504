.picture {
  position: relative;
  display: block;
}
.placeHolder {
  max-width: 100%;
  width: 100%;
  opacity: 0.00;
}
.loaded {
  transition: opacity 0.3s ease-in;
  opacity: 1;
}
.mainImg {
  //transition: opacity 0.9s ease-in;
  position: absolute;
  left: 0;
  top:0;
  width: 0;
  height: 0;
  opacity: 0;
}